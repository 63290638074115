<template>
  <div>
    <div class="projects">
      <router-link :to="'/works/about/performances'" class="noUnderline" v-if="performances === 'Performances' && !visual">
        <div class="projectTitle">About Performances</div>

        <div class="aboutTextsOuter">
          <div class="aboutTexts">
            <div class="aboutTextsTop" v-html="$options.filters.truncate(texts['Performances'][0].text, 210)"></div>
            <div class="aboutTextsBottom"><p style="text-decoration: underline">Read more...</p></div>
          </div>
        </div>
      </router-link>

      <router-link :to="'/works/about/visual-works'" class="noUnderline" v-if="!performances && visual === 'Visual Works'">
        <div class="projectTitle">About Visual Works</div>

        <div class="aboutTextsOuter">
          <div class="aboutTexts">
            <div class="aboutTextsTop" v-html="$options.filters.truncate(texts['Visual Works'][0].text, 210)"></div>
            <div class="aboutTextsBottom"><p style="text-decoration: underline">Read more...</p></div>
          </div>
        </div>
      </router-link>

      <template v-for="project in $store.state.current">
        <div v-if="project.type === performances || project.type === visual" :key="project.title">
          <router-link :to="'/works/single/' + project.url" class="noUnderline">
            <div class="projectTitle" v-html="$options.filters.truncate(project.title, 35)"></div>

            <div class="thumbAll projectThumb">
              <div class="projectThumbInner">
                <img :src="$store.state.cmsFiles + project.thumbnail.small" />
              </div>
            </div>
          </router-link>
        </div>
      </template>
    </div>

    <div class="title">Archive</div>

    <div class="projects">
      <template v-for="project in $store.state.archive">
        <div v-if="project.type === performances || project.type === visual" :key="project.title">
          <router-link :to="'/works/single/' + project.url" class="noUnderline">
            <div class="projectTitle" v-html="$options.filters.truncate(project.title, 35)"></div>

            <div class="thumbAll projectThumb">
              <div class="projectThumbInner">
                <img :src="$store.state.cmsFiles + project.thumbnail.small" />
              </div>
            </div>
          </router-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
var VueTruncate = require('vue-truncate-filter');
Vue.use(VueTruncate);

export default {
  props: ['performances', 'visual', 'texts'],
};
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

.aboutTextsOuter {
  position: relative;
  padding-bottom: 62.5%;
}

.aboutTexts {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: $boxPadding;
  border: $border-square;
  display: flex;
  flex-direction: column;
}

.aboutTextsTop {
  flex: 1;
}

.aboutTexts p:last-child {
  margin-bottom: 0;
}

.projects {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: $objectPadding;
  grid-row-gap: $projectSpacing;
}

@media screen and (max-width: $breakPoint1) {
  .projects {
    grid-template-columns: 1fr;
  }
}
</style>
