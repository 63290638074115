<template>
  <div id="app" :class="['highlight' + $store.state.month, $route.name]">
    <div id="siteTitle"><router-link to="/">Dreams Come True</router-link></div>
    <div id="menu">
      <div class="menuItem">
        <router-link to="/" exact><div class="menuItemInner" id="calendar">Calendar</div></router-link>
      </div>

      <div class="menuItem">
        <router-link :to="workLink" :class="[$route.name === 'single' || $route.name === 'about works' || $route.name === 'performances' ? 'router-link-active' : '']"
          ><div class="menuItemInner" id="works">Works</div></router-link
        >
      </div>

      <div class="menuItem">
        <router-link to="/information"><div class="menuItemInner" id="Information">Information</div></router-link>
      </div>
    </div>

    <router-view></router-view>

    <cookie-law theme="duyvendak" :class="$store.state.month"></cookie-law>
  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law';

export default {
  data() {
    return {
      workLink: '/works',
    };
  },

  components: { CookieLaw },

  watch: {
    $route(to, from) {
      if (to.meta.scroll === 'works') {
        if (to.path === '/works/all') {
          this.workLink = '/works';
        } else {
          this.workLink = to.path;
        }
      }
    },
  },

  methods: {
    checkTouch() {
      this.$store.commit('isTouch', true);
      window.removeEventListener('touchstart', this.checkTouch);
    },
  },

  mounted() {
    window.addEventListener('touchstart', this.checkTouch);
    if (!this.$store.state.information) {
      this.$axios.get('/information').then((result) => {
        this.$store.commit('information', result.data.contacts);
      });
    }
  },
};
</script>

<style lang="scss">
@import '../node_modules/normalize.css/normalize.css';
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/global.scss';

.Cookie--duyvendak {
  padding: $mainPadding;
}

.Cookie > * {
  margin: 0;
}

.Cookie--duyvendak .Cookie__buttons {
  margin: 0;
}

.Cookie--duyvendak .Cookie__button {
  margin: 0;
  background-color: white;
  padding: $boxPadding;
  border-radius: 10rem;
  border: $border-square;
  transition: background-color 0.5s, color 0.5s;
}

.Cookie--duyvendak .Cookie__button:hover {
  background-color: black;
  color: white;
}

#siteTitle {
  height: 7rem;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'Eric Medium';
  font-size: $largeText;
  line-height: $largeText;
  padding: 1.5rem 0 0 $mainPadding;
  z-index: 99;
  position: relative;

  a {
    text-decoration: none;
  }
}

#dreams {
  position: absolute;
  top: 5.5rem;
  left: 2.2rem;
  z-index: 3;
}

.single #siteTitle,
.calendar #siteTitle {
  position: absolute;
}

.single #siteTitle a,
.calendar #siteTitle a {
  color: white;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.single #dreams,
.calendar #dreams {
  color: white;
}

#menu {
  padding-right: $mainPadding;
  position: fixed;
  top: 1.9rem;
  right: 0;
  z-index: 99;
  display: flex;
  justify-content: right;
  flex-direction: row;

  a {
    text-decoration: none;
  }
}

.router-link-active .menuItemInner,
.menuItemInner:hover {
  background-color: black;
  color: white;
}

.menuItem {
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: $objectPadding;
}

.menuItem:first-child {
  margin-left: 0;
}

.menuItemInner {
  transition: color 0.5s, background-color 0.5s;
  background-color: white;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border: $border-square;
  border-radius: 10rem;
  padding: $boxPaddingRound;
}

@media screen and (max-width: $breakPoint1) {
  .Cookie__content {
    margin-bottom: $lineHeight;
  }

  #siteTitle {
    font-size: $largeTextMob;
    line-height: $largeTextMob;
  }

  .info #menu,
  .about #menu,
  .auteur #menu {
    margin-bottom: 0;
  }

  #menu {
    margin-bottom: $mainPadding;
    padding: 0 $mainPadding;
    position: sticky;
    justify-content: left;
    top: 2rem;
    text-align: center;
  }

  .menuItem {
    flex-grow: 3;
  }

  .single #siteTitle,
  .calendar #siteTitle {
    position: relative;
  }

  .single #siteTitle a,
  .calendar #siteTitle a {
    color: black;
    text-shadow: none;
  }

  .single #dreams,
  .calendar #dreams {
    color: black;
  }
}
</style>
