<template>
  <div class="event">
    <project-title :project="performance" :ref="'titleHover' + index"></project-title>

    <div class="eventContent">
      <div class="days">
        <div class="day" v-for="(day, index) in performance.days" :class="[performance.days.length === 1 ? 'singleDay' : '']" :key="day + index">{{ day }}</div>
      </div>

      <div class="eventImage">
        <project-thumb :project="performance" :index="index" @highlight="highlightTitle"></project-thumb>
      </div>

      <div class="eventInfo">
        <template v-if="performance.venue.event">
          <div class="eventName"><a :href="performance.venue.event" v-html="performance.venue.name" target="_blank"></a></div>
        </template>
        <template v-else>
          <div class="eventName" v-html="performance.venue.name"></div>
        </template>
        <div class="eventAddress" v-html="performance.venue.address" v-if="performance.venue.address"></div>
      </div>

      <auteurs :auteurs="performance.auteurs" />
    </div>
  </div>
</template>

<script>
import ProjectTitle from '@/components/A/Reusable/Title';
import ProjectThumb from '@/components/A/Reusable/Thumb';
import FilterMixins from '@/components/A/Mixins/FilterMixins';
import Auteurs from '@/components/A/Reusable/Auteurs';

export default {
  data() {
    return {
      thumbnail: this.$store.state.cmsFiles + this.performance.thumbnail.src,
    };
  },

  components: {
    ProjectTitle,
    ProjectThumb,
    Auteurs,
  },

  props: ['performance', 'index', 'month'],

  mixins: [FilterMixins],
};
</script>
